// extracted by mini-css-extract-plugin
export var bottomAbsoluteStart = "variants-module--bottomAbsoluteStart--2RYgY";
export var bottom = "variants-module--bottom--3TZFt";
export var bottomStart = "variants-module--bottomStart--LrQ30";
export var bottomEnd = "variants-module--bottomEnd--3x7T1";
export var bottomAbsoluteEnd = "variants-module--bottomAbsoluteEnd--1Idk6";
export var topAbsoluteStart = "variants-module--topAbsoluteStart--KXpUl";
export var top = "variants-module--top--VexGL";
export var topStart = "variants-module--topStart--1Y0Zk";
export var topEnd = "variants-module--topEnd--3Ntqd";
export var topAbsoluteEnd = "variants-module--topAbsoluteEnd--3LnKE";
export var leftAbsoluteStart = "variants-module--leftAbsoluteStart--3wNCw";
export var left = "variants-module--left--2QoM0";
export var leftStart = "variants-module--leftStart--e3DEk";
export var leftEnd = "variants-module--leftEnd--1Uc7j";
export var leftAbsoluteEnd = "variants-module--leftAbsoluteEnd--1KN0S";
export var rightAbsoluteStart = "variants-module--rightAbsoluteStart--3rgY2";
export var right = "variants-module--right--3IY8K";
export var rightStart = "variants-module--rightStart--3x66a";
export var rightEnd = "variants-module--rightEnd--D2Hli";
export var rightAbsoluteEnd = "variants-module--rightAbsoluteEnd--2jEEG";