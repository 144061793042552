// extracted by mini-css-extract-plugin
export var root = "style-module--root--ZOpN6";
export var mobile = "style-module--mobile--upgHf";
export var stories = "style-module--stories--1nkKQ";
export var story = "style-module--story--3Kc3A";
export var thanksReact = "style-module--thanksReact--1SU-_";
export var story2 = "style-module--story2--1me61";
export var story3 = "style-module--story3--6vjO_";
export var section = "style-module--section--mJJq3";
export var subtitle = "style-module--subtitle--2RLoQ";
export var skills = "style-module--skills--3tNiR";
export var skill = "style-module--skill--3Nczg";
export var label = "style-module--label--1lEcj";
export var progress = "style-module--progress--2llRG";
export var story1 = "style-module--story1--2BjjJ";