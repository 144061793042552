// extracted by mini-css-extract-plugin
export var legend = "style-module--legend--2rHrx";
export var legendItem = "style-module--legendItem--12Upa";
export var dot = "style-module--dot--25Fzd";
export var legendDot = "style-module--legendDot--e6lMB";
export var timelineNavigationContainer = "style-module--timelineNavigationContainer--3-ZmR";
export var timelineNavigation = "style-module--timelineNavigation--1tDaP";
export var stepBtn = "style-module--stepBtn--1O9fV";
export var experience = "style-module--experience--JFvM1";
export var experienceHeader = "style-module--experienceHeader--2Qmmo";
export var title = "style-module--title--1KvqZ";
export var duration = "style-module--duration--DHzS7";
export var headerLastItem = "style-module--headerLastItem--ZrTYt";
export var story = "style-module--story--10l7d";
export var usedTechs = "style-module--usedTechs--3bVk-";
export var drawer = "style-module--drawer--2K2Ip";
export var largeVp = "style-module--largeVp--PNruW";
export var connector = "style-module--connector--1NUjm";
export var card = "style-module--card--2nzpA";
export var tech = "style-module--tech--38i0x";
export var smallVp = "style-module--smallVp--1eIrZ";
export var mobile = "style-module--mobile--1N7H6";
export var timeline = "style-module--timeline--11oUs";
export var education = "style-module--education--3LpQw";
export var employee = "style-module--employee--3IuAn";
export var freelance = "style-module--freelance--qlpno";