// extracted by mini-css-extract-plugin
export var root = "style-module--root--3yftQ";
export var opaque = "style-module--opaque--1woW3";
export var brand = "style-module--brand--3DmAX";
export var rightSection = "style-module--rightSection--2mV6L";
export var nav = "style-module--nav--2dTea";
export var navSmallVp = "style-module--navSmallVp--37S8X";
export var navLink = "style-module--navLink--f9HKr";
export var activeNavLink = "style-module--activeNavLink--OHaHP";
export var githubLink = "style-module--githubLink--eO0B6";
export var menuToggle = "style-module--menuToggle--oVVa2";